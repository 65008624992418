<template>
  <div style="padding: 32px">
    <el-table :data="cerList">
      
      <el-table-column label="Certificate">
        <template #default="scope">
          {{ scope.row.award_lable
          }}
        </template>
      </el-table-column>
      <el-table-column label="Project">
        <template #default="scope">
          {{ scope.row.project_info.project_ename
          }}
        </template>
      </el-table-column>

     
      <el-table-column label="View">
        <template slot-scope="scope">
          <el-button @click="view(scope.row)" type="primary" size="small"
            >View</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="showD">
      <div style="align-items: center;text-align: center;">

        <span>Please select your role to be displayed on the certificate.</span>
      </div>

      <div style="align-items: center;text-align: center;margin-top: 30px;">
        <el-button  size="medium"  @click="toCer('1')" type="primary">Adviser</el-button>

        <el-button  size="medium"  @click="toCer('2')" type="primary">Leading Adviser</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAccountContactCertificate
} from "../../api/eae";
import { getAccountId, getUserId, ENTRY_TYPES } from "../../utils/store";
import { uniqBy } from "lodash-es";
export default {
  data() {
    return {
      cerList: [],
      showD:false,
    };
  },
 
  mounted() {
    this.fetchData();
    // this.fetchData();
  },
  methods: {
    
    toCer(type){
      
          this.$router.push({
            path: '/home/advisorCertificate',
            query: {
              show:type,
            }
          })


          this.showSelect = false
    },
    view(row){
      this.showD = true
    },
   
    fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getAccountContactCertificate(getUserId()).then((res) => {
          if (res.data.code === 0) {
            
              this.cerList = res.data.data;
          }
        })
        .finally(() => loading.close());
    },
   
    goDetail(item) {
      this.$router.push({
        path: "/home/studentDetail",
        query: {
          id: item.student_info._id.$id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.student-item {
  width: calc(50% - 10px);
  background-color: white;
  padding: 18px 24px;

  .student-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 18px;
  }

  .view-btn {
    padding: 6px 26px;
    border-radius: 5px;
    cursor: pointer;
    background: #579eff;
    color: white;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
}

.table-header {
  width: calc(100% / 6);
  word-wrap: break-word;
  padding: 12px;
  font-size: 14px;
  display: flex;
  align-items: center;

  &.active {
    color: #ff6450;
    font-weight: bold;
  }

  &.pending {
    color: #67c23a;
    font-weight: bold;
  }

  &.expired {
    color: #909399;
    font-weight: bold;
  }
}

.header-wrapper {
  background: rgba(#ff6450, 0.15);
}

.content-wrapper {
  background-color: white;
}

.entry-title {
  width: calc(100% / 6);
  word-wrap: break-word;
  padding: 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
</style>
